import {
  addDays,
  formatDuration,
  intervalToDuration,
  roundToNearestMinutes,
} from "date-fns";

export default function dateFormat(amount: number) {
  return addDays(new Date(), amount)
    .toLocaleDateString("en-IN")
    .split("/")
    .reverse()
    .join("-");
}

export const remainingTime = (date: string) => {
  try {
    const mapLabels = {
      years: "y",
      month: "M",
      weeks: "w",
      days: "d",
      hours: "h",
      minutes: "m",
      seconds: "s",
    };
    const duration = intervalToDuration({
      start: date ? new Date(date) : new Date(),
      end: new Date(),
    });
    const units = [
      "years",
      "months",
      "weeks",
      "days",
      "hours",
      "minutes",
      "seconds",
    ];
    const nonzero = Object.entries(duration)
      .filter(([_, value]) => value || 0 > 0)
      .map(([unit, _]) => unit);
    const format = formatDuration(duration, {
      format: units.filter((i) => new Set(nonzero).has(i)).slice(0, 3),
      delimiter: ", ",
    }).split(", ");

    return (
      format[0]
        .replace(
          /\syears|month|weeks|days|hours|minutes|seconds/gi,
          (matched) => mapLabels[matched]
        )
        .replace(" ", "") +
      " " +
      format[1]
        .replace(
          /\syears|month|weeks|days|hours|minutes|seconds/gi,
          (matched) => mapLabels[matched]
        )
        .replace(" ", "")
    );
  } catch {
    return "0s";
  }
};
